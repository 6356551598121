<template>
  <div class="shiyong">
    <!-- <div class="shiyong-header">
      <div class="flex">
        <el-image class="left-logo" :src="logo" fit="contain"></el-image>
        <div class="line"></div>
        <div class="line-title">{{ new Date().getFullYear() }}报考指南</div>
      </div>
      <div class="flex">
        <el-dropdown class="dropBox" trigger="click">
          <div class="title">
            <div class="elp">
              {{ search.schooleName ? search.schooleName : '大类筛选' }}
            </div>
            <i class="iconfont icon-a-Group1895"></i>
          </div>
          <el-dropdown-menu class="onClick-shiyong" slot="dropdown">
            <el-dropdown-item v-for="(item, index) in collageList" :key="index">
              <div class="pname" :class="search.schoole == item.pKind ? 'onClick' : ''" @click="school(item)">
                {{ item.pName }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown class="dropBox" trigger="click">
          <div class="title">
            <div class="elp">
              {{ search.kindName ? search.kindName : '项目筛选' }}
            </div>
            <i class="iconfont icon-a-Group1895"></i>
          </div>
          <el-dropdown-menu class="onClick-shiyong" slot="dropdown">
            <el-dropdown-item v-for="(item, index) in kindList" :key="index">
              <div class="pname" :class="search.kind == item.pKind ? 'onClick' : ''" @click="getDetail(item)">
                {{ item.pName }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div> -->
    <!-- <div class="exm-time">
      <div v-html="detail ? detail.dateContent : ''"></div>
    </div> -->
    <div class="centerHeader">
      <div class="isHeader">
        <div class="left">
          <div class="headerImg" />
          <div class="headeName">报考指南</div>
        </div>
        <div class="right">
          <!-- <el-dropdown class="dropBox" trigger="click">
            <div class="title">
              {{ search.schooleName ? search.schooleName : "大类筛选" }}
              <i class="iconfont icon-a-Group1895" />
            </div>
            <el-dropdown-menu slot="dropdown" class="onClick-shiyong">
              <el-dropdown-item
                v-for="(item, index) in collageList"
                :key="index"
              >
                <div
                  class="pname"
                  :class="search.schoole == item.pKind ? 'onClick' : ''"
                  @click="school(item)"
                >
                  {{ item.pName }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-dropdown class="dropBox" trigger="click">
            <div class="title">
              <div class="elp">
                {{ search.kindName ? search.kindName : "项目筛选" }}
              </div>
              <i class="iconfont icon-a-Group1895" />
            </div>
            <el-dropdown-menu slot="dropdown" class="onClick-shiyong">
              <el-dropdown-item v-for="(item, index) in majorList" :key="index">
                <div class="pname" :class="search.kind == item.pKind ? 'onClick' : ''" @click="getDetail(item)">
                  {{ item.pName }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 分类列表 -->
      <div class="entrance-box">
        <div v-for="(item, index) in exm" :key="index" class="cillapseIItem">
          <el-collapse v-model="menuOneId" @change="bigCheck(item)">
            <el-collapse-item :key="index" :name="item.id" class="item">
              <template slot="title">
                <div class="item-title">{{ item.menuName }}</div>
              </template>
              <div v-for="(chil, chilIndex) in item.menuList" :key="chilIndex" class="content-text"
                :class="menuTwoId == chil.id ? 'on' : ''" @click="check(chil)">
                <div class="onIcon" />
                {{ chil.menuName }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <!-- 实用详情 -->
      <div class="detail">
        <div v-html="detail ? detail.content : ''" />
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { Know } from "@/api/know";
const know = new Know();
import { getInfo, getDomainLogo } from "@/api/cookies";
import {
  getTenantidNavigation,
  selectKindAllCollage,
  selectZyList,
  getSurfaceList,
} from "@/api/home";
export default {
  components: {},

  data() {
    return {
      logo: "",
      exm: [],
      userInfo: null,
      menuOneId: null,
      menuTwoId: null,
      choseType: null,
      detail: null,
      kindList: [],
      majorList: [],
      collageList: [], // 学院
      search: {
        schoole: null,
        schooleName: null,
        kind: null,
        kindName: null,
      },
    };
  },

  async created() {
    this.logo = getDomainLogo();
    this.menuOneId = this.$route.query.menuOneId
      ? this.$route.query.menuOneId.split(",").map(String)
      : null;
    this.menuTwoId = this.$route.query.menuTwo
      ? this.$route.query.menuTwo
      : null;
    this.search.schoole = this.$route.query.schoolId
      ? this.$route.query.schoolId
      : null;
    this.search.kind = this.$route.query.kind ? this.$route.query.kind : null;

    this.userInfo = getInfo();
    await this.getExmInfo(true);
    if (this.menuOneId && this.menuOneId.length > 0) {

      for (const item of this.exm) {

        if (this.menuOneId[0] == item.id) {
          for (const chil of item.menuList) {

            if (this.menuTwoId == chil.id) {
              this.check(chil);
              break;
            }
          }
          break;
        }
      }
    }
    await this.getshoolList();
    await this.getKind();
    for (const item of this.collageList) {
      if (this.search.schoole == item.pKind) {
        await this.school(item, true);
        break;
      }
    }
    for (const item of this.majorList) {
      if (this.search.kind == item.pKind) {
        this.getDetail(item, true);
        break;
      }
    }
  },
  methods: {
    // 大类
    async getshoolList() {
      await selectKindAllCollage().then((res) => {
        if (res.code == 0) {
          this.collageList = res.data;
          // this.kindIndex = res.data[0];
        } else {
          this.collageList = [];
        }
      });
    },
    async getKind() {
      await getTenantidNavigation(this.search.schoole).then((res) => {
        if (res.code == 0) {
          this.kindList = res.data;
          const oldList = [];
          //  
          this.kindList.filter((item) => {
            if (item.pKind == 3) {
              item.num = 1;
              item.pName = "统招专升本";
              oldList.push(item);
            }
            if (item.pKind == 1) {
              item.num = 2;
              item.pName = "自考专升本";
              oldList.push(item);
            }
            if (item.pKind == 9) {
              item.num = 3;
              item.pName = "普通考研";
              oldList.push(item);
            }
            if (item.pKind == 6) {
              item.num = 4;
              item.pName = "教师资格证";
              oldList.push(item);
            }
            if (item.pKind == 7) {
              item.num = 5;
              item.pName = "计算机等级";
              oldList.push(item);
            }
            if (item.pKind == 5) {
              item.num = 6;
              item.pName = "英语等级";
              oldList.push(item);
            }
          });
          const len = oldList.length;
          for (let i = 0; i < len; i++) {
            for (let j = 0; j < i; j++) {
              if (oldList[j].num > oldList[i].num) {
                const temp = oldList[j];
                oldList[j] = oldList[i];
                oldList[i] = temp;
              }
            }
          }
          this.majorList = oldList;
        } else {
          this.kindList = [];
        }
      });
    },
    /* 实用信息 */
    async getExmInfo(noClick) {

      await know.informMenuPcList(undefined, this.search.kind).then((res) => {

        this.exm = res.data;
        if (!noClick) {
          this.menuTwoId = null;
          this.detail = null;
          this.check(this.exm[0]?.menuList[0]);
        }
      });
    },
    /* 点击大类 */
    check(chil) {
      if (chil) {
        this.detail = chil;
        this.menuTwoId = chil.id;
      }

      this.$forceUpdate();
    },
    async school(val, noClick) {
      if (val) {
        this.search.schoole = val.pKind;
        this.search.schooleName = val.pName;
      }
      if (!noClick) {
        this.majorList = await val.majorList;
        await this.getDetail(val?.majorList[0]);
      } else {
        await this.getExmInfo(noClick);
      }
    },
    /* 项目修改 */
    getDetail(kind, noClick) {
      /*   if (this.search.kind == kind.pKind) {
        this.search.kind = null;

        this.search.kindName = null;
      } else */
      this.search.kind = kind.pKind;
      this.search.kindName = kind.pName;

      this.getExmInfo(noClick);
    },

    bigCheck(val) {
      this.choseType = val;

    },
  },
};
</script>
<style lang="less" scoped>
.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.el-dropdown-menu {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

/* 下拉筛选 */
/deep/ .dropBox {
  margin-bottom: 16px;
  margin-top: 28px;
  margin-left: 28px;
  max-width: 600px;

  .title {
    /* font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400; */
    color: #ffffff;
    line-height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #ffffff;
    padding: 0px 14px;
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;

    .iconfont {
      font-size: 20px;
      color: #fff;
      margin-left: 8px;
      flex-shrink: 0;
    }
  }
}

// 大盒子
.shiyong {
  width: 100%;
  height: auto;
  position: relative;

  .centerHeader {
    margin: 14px 0px 11px;
    display: flex;
    justify-content: center;
    align-items: center;

    .isHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;

      .left {
        display: flex;
        align-items: center;

        .headeName {
          margin-left: 6px;
          font-size: 26px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #4e4e4e;
        }

        .headerImg {
          width: 24px;
          height: 26px;
          background: url("~@/assets/img/homeSeventh/baokaoImg.png") no-repeat top right / 100% 100%;
          opacity: 1;
          margin-top: 4px;
        }
      }

      .right {
        .dropBox {
          margin-bottom: 0px;
          margin-top: 0px;
          margin-left: 0px;
          background: #f4f7f9;
          margin-left: 30px;

          .title {
            display: flex;
            justify-content: center;
            min-width: 100px;
            height: 36px;
            border-radius: 6px 6px 6px 6px;
            opacity: 1;
            border: 1px solid #00ab8c;
            color: #00ab8c;
            padding: 8px;
            font-size: 14px;

            .elp {
              font-size: 14px;
            }
          }
        }

        .iconfont {
          font-size: 6px;
          color: #00ab8c;
        }
      }
    }
  }

  .shiyong-header {
    background: url("~@/assets/img/homeSeventh/shiyongBg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 220px;
    padding: 37px calc(50% - 810px) 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-logo {
      height: 68px;
    }

    .line {
      width: 1px;
      height: 50px;
      border: 1px solid #ccd7ff;
      margin: 0px 33px;
    }

    .line-title {
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }

  /* 时间排序 */
  .exm-time {
    width: 1619px;
    height: 193px;
    background: #ffffff;
    box-shadow: -1px 0px 6px 0px #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 0px calc(50% - 810px);
    padding: 24px;
    top: 141px;
    overflow: hidden;
  }

  /* 内容 */
  .content {
    width: 1200px;
    margin: 0px calc(50% - 698px) 0px;
    display: flex;

    /deep/ .entrance-box {
      width: 260px;
      // background: #ffffff;
      // box-shadow: -1px 0px 6px 0px #d8d8d8;
      border-radius: 8px;
      margin-right: 20px;
      /*   max-height: 80vh; */
      overflow: hidden;
      overflow-y: scroll;

      .cillapseIItem {
        background-color: #fff;
        margin-bottom: 10px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        overflow: hidden;

        .spread {
          width: 8px;
          height: 14px;
          background: url("~@/assets/img/homeSeventh/showIcon.png") no-repeat center center / 100% 100%;
        }

        .isshow {
          width: 14px;
          height: 8px;
          background: url("~@/assets/img/homeSeventh/hiddenIcon.png") no-repeat center center / 100% 100%;
        }
      }

      .el-collapse {
        width: 100%;
      }

      .item {

        // .el-collapse-item__arrow {
        //   display: none;
        // }
        .el-collapse-item__header {
          width: 100%;
          height: 72px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 72px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          background: none;
          padding: 29px 0px;

          .item-title {
            // width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .el-collapse-item__arrow {
          margin-left: 5px;
        }

        /* 二级分类 */
        .el-collapse-item__content {
          padding: 9px 0px !important;
        }

        .content-text {
          position: relative;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 25px;
          cursor: pointer;
          padding: 9px 55px;

          .onIcon {
            display: none;
          }
        }

        .on {
          color: #00ab8c !important;

          .onIcon {
            display: block;
            position: absolute;
            width: 4px;
            height: 4px;
            background: #00ab8c;
            border-radius: 50%;
            left: 40px;
            top: 19px;
          }
        }
      }

      .is-active {
        background: url("~@/assets/img/homeSeventh/shiyongTitleBg.png");
        background-size: 100% 100%;
        color: #ffffff !important;
      }
    }

    // ::v-deep
    /deep/ .detail {
      width: 1297px;
      // max-height: 80vh;
      overflow-y: scroll;
      background: #ffffff;
      box-shadow: -1px 0px 6px 0px #d8d8d8;
      border-radius: 8px;
      padding: 57px 63px;
      margin: revert !important;

      p {
        line-height: 2 !important;
        padding: inherit !important;
        margin: revert !important;
      }
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}
</style>
